<template>
  <div class="merchants table-wrap">
    <el-table border :data="list" style="width: 100%" :row-style="{ height: 0 }" :cell-style="{ padding: '8px 0' }"
      :default-sort="{ prop: 'createAt', order: 'descending' }" @sort-change="sortChange">
      <el-table-column prop="tempId" label="ID" align="center" show-overflow-tooltip max-width="30"></el-table-column>
      <el-table-column prop="realName" label="商户名称" align="center" width="120"></el-table-column>
      <el-table-column prop="username" label="账号" align="center" width="120"></el-table-column>
      <!-- <el-table-column
        prop="mobile"
        label="手机号"
        align="center"
        min-width="110"
      ></el-table-column> -->
      <el-table-column prop="promoter.nickname" label="推广专员" align="center"></el-table-column>
      <el-table-column prop="amount" sortable="custom" width="100" align="center" label="余额">
        <template slot-scope="scope">{{
      (scope.row.amount / 100).toFixed(2)
    }}</template>
      </el-table-column>
      <el-table-column prop="windMoney" sortable="custom" align="center" label="风控累计充值(元)" v-if="pro">
        <template slot-scope="scope">{{
      Math.floor(scope.row.windMoney / 100)
    }}</template>
      </el-table-column>
      <el-table-column align="center" label="星币余额" v-if="pro">
        <template slot-scope="scope">{{
      Math.floor(scope.row.promote / 100)
    }}</template>
      </el-table-column>
      <el-table-column prop="promoteMoney" sortable="custom" align="center" label="星币累计充值" v-if="pro">
        <template slot-scope="scope">{{
      Math.floor(scope.row.promoteMoney / 100)
    }}</template>
      </el-table-column>
      <el-table-column prop="allMoney" sortable="custom" align="center" label="总累计充值(元)" v-if="pro">
        <template slot-scope="scope">{{
      Math.floor(scope.row.allMoney / 100)
    }}</template>
      </el-table-column>
      <el-table-column prop="createAt" :formatter="notSecondDateFormat" align="center" label="注册时间" width="150"
        sortable="custom"></el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-tag :effect="exam[scope.row.status].efc" :type="exam[scope.row.status].type" size="mini">{{
      exam[scope.row.status].text }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="认证状态" class-name="table-verify" width="110">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            :color="scope.row.authLevel ? '#5766DA' : '#eee'"
            :class="scope.row.authLevel ? 'tag' : 'un-tag'"
          >{{ scope.row.authLevel ? '认证M' + scope.row.authLevel : '未认证' }}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column label="类型" align="center" width="128" v-if="pro">
        <template slot-scope="scope">
          <el-select v-model="scope.row.roleId" @change="onChangeXjd(scope.row)">
            <el-option v-for="item in xjdArr" :key="item.id" :label="item.roleName" :value="item.id">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="共享" align="center" width="128" v-if="pro">
        <template slot-scope="scope">
          <!-- <el-switch v-model="scope.row.is_share" @change="onChangeShare(scope.row)"></el-switch> -->
          <el-select v-model="scope.row.isPromote" @change="onChangeShare(scope.row)">
            <el-option v-for="item in promoteArr" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <!-- 查看 -->
          <el-button type="success" size="mini" icon="el-icon-edit" circle
            @click="$emit('edit', scope.row)"></el-button>
          <!-- 删除 -->
          <el-button type="danger" size="mini" icon="el-icon-delete" class="check-btn" circle
            @click="$emit('del', scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import format from "@/mixins/date.js";
import dayjs from "dayjs";
import service from '../api'
export default {
  mixins: [format],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      exam: {
        0: { type: "primary", text: "通过", efc: "dark" },
        1: { type: "danger", text: "拒绝", efc: "dark" },
        2: { type: "info", text: "待审核", efc: "plain" }
      },
      promoteArr: [
        { value: 0, label: "关闭" },
        { value: 1, label: "开启" },
        { value: 2, label: "静默开启" }
      ],
      xjdArr: []
    };
  },
  methods: {
    handleView(row) {
      this.$emit("view", row);
    },
    onChangeXjd(row) {
      this.$emit("changeXjd", row);
    },
    onChangeShare(row) {
      this.$emit("changeShare", row);
    },
    sortChange(val) {
      this.$emit('sortChange', val)
    },
    getXjdArr() {
      return service.getMerchantRoles().then(res => {
        this.xjdArr = res;
      })
    }
  },
  created() {
    this.getXjdArr()
  }
};
</script>

<style lang="scss" scoped>
.merchants {
  .tag {
    color: #fff;
  }

  .un-tag {
    color: #666;
  }

  .check-btn {
    margin-left: 10px;
  }
}
</style>
